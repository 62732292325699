@import '../theme-bootstrap';

$play-icon-size-pc: 80px;
$play-icon-size-mobile: $play-icon-size-pc / 2;
$opacity-units: 50 60 70 80 90 91 92 93 94 95 96 97 98 99 100;
$size-units: 10 20 30 40 50 60 70 80 90 100;
$percentage-unit: unquote('%');

.video-play-trigger {
  height: $play-icon-size-mobile;
  width: $play-icon-size-mobile;
  @media #{$cr19-large-up} {
    height: $play-icon-size-pc;
    width: $play-icon-size-pc;
  }
  &::before {
    content: ' ';
    margin-#{$ldirection}: 6px;
    top: 50%;
    transform: translate(0, -50%);
    position: relative;
    display: block;
    filter: drop-shadow(0 4px 4px $video-play-icon-shadow-color);
    @media #{$cr19-large-up} {
      margin-#{$ldirection}: 12px;
    }
    .play-icon-color-black & {
      @include triangle($play-icon-size-mobile, $color-black, #{$rdirection});
      border-width:
        $play-icon-size-mobile / 2
        $play-icon-size-mobile / 2
        $play-icon-size-mobile / 2
        $play-icon-size-mobile / 2 + 10;
      @media #{$cr19-large-up} {
        @include triangle($play-icon-size-pc, $color-black, #{$rdirection});
        border-width:
          $play-icon-size-pc / 2
          $play-icon-size-pc / 2
          $play-icon-size-pc / 2
          $play-icon-size-pc / 2 + 20;
      }
    }
    .play-icon-color-white & {
      @include triangle($play-icon-size-mobile, $color-white, #{$rdirection});
      border-width:
        $play-icon-size-mobile / 2
        $play-icon-size-mobile / 2
        $play-icon-size-mobile / 2
        $play-icon-size-mobile / 2 + 10;
      @media #{$cr19-large-up} {
        @include triangle($play-icon-size-pc, $color-white, #{$rdirection});
        border-width:
          $play-icon-size-pc / 2
          $play-icon-size-pc / 2
          $play-icon-size-pc / 2
          $play-icon-size-pc / 2 + 20;
      }
    }
  }
  &:hover {
    transform: translate(-50%, -50%) scale(1.1, 1.1);
  }
  @each $value in $size-units {
    .play-icon-size-#{$value} & {
      transform: translate(-50%, -50%) scale(calc(1 * #{$value} / 100), calc(1 * #{$value} / 100));
    }
  }
}

#foreground-node.colorbox--video {
  padding: 0;
  border: none;
  z-index: 99999;
  position: fixed;
  height: auto !important;
  @media #{$small-only} {
    top: 50% !important;
    margin-top: -110px;
  }
  @media #{$cr19-medium-portrait-only} {
    top: 50% !important;
    margin-top: -220px;
  }
  @media #{$cr19-large-up} {
    position: absolute;
  }
  .close-container {
    position: fixed;
    background: $color-white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    top: 30px;
    #{$rdirection}: 10px;
    @media #{$cr19-large-up} {
      #{$rdirection}: 75px;
      top: 35px;
    }
    .close-link {
      margin: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      background: url('#{$base-theme-path}img/icons/src/x-16.svg') no-repeat;
      width: 18px;
      height: 17px;
      .device-mobile & {
        background-position: center;
      }
    }
  }
  .vjs_video_3-dimensions.vjs-fluid,
  .videojs-video,
  .video-js {
    padding-top: 56.25%;
  }
  .videojs-video {
    z-index: 100;
  }
  video {
    display: block;
    width: 100%;
    max-height: 100%;
  }
}

/*
 * Replicate the spinning pink circle normally found on the landing play icon.
 * The .vjs-loading-spinner shows up on the video itself, which is usually
 * hidden when playing inline.
 */
.vjs-loading-spinner {
  border: 0;
  // Hide loading spinner for mobile
  display: none;
  @media #{$cr19-large-up} {
    display: block;
  }
  &::before {
    display: none;
  }
}

.vjs-fullscreen-control {
  &.vjs-button {
    // Hide full screen button for mobile
    display: none;
    @media #{$cr19-large-up} {
      display: block;
    }
  }
}

// Mantle video styles
$mobile-landscape: 0 (max-height 480px) (orientation landscape) !default;

@keyframes video-loading-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.videojs-video {
  // i..e video element
  position: relative;
  background: $color-black;
  padding-top: 56.25%;
  &__player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    z-index: 0;
  }
  &__play {
    position: absolute;
    z-index: 100;
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      cursor: pointer;
    }
  }
  &__landing {
    overflow: hidden;
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    &__inner {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 100%;
    }
    &__image img {
      position: absolute;
      max-width: none;
      max-height: 100%;
      #{$ldirection}: 50%;
      height: 100%;
      width: auto;
      transform: translateX(-50%);
      outline-offset: -20px;
      @media #{$cr19-large-up} {
        outline-offset: -30px;
      }
      @each $value in $opacity-units {
        .poster-image-transparency-#{$value} & {
          filter: brightness(#{$value}$percentage-unit);
        }
      }
      .playing-video &,
      .colorbox--video & {
        filter: brightness(100%);
      }
      [dir='rtl'] & {
        #{$rdirection}: 50%;
        #{$ldirection}: auto;
      }
    }
  }
  &.videojs-video--loading &__landing {
    .video-play-trigger {
      &::after {
        position: absolute;
        top: -20px;
        #{$ldirection}: -20px;
        content: ' ';
        display: block;
        margin: 0;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        border-radius: 50%;
        border: 10px solid transparent;
        border-color: $color-white transparent transparent transparent;
        animation: video-loading-circle 1.5s linear infinite;
        @media #{$cr19-large-up} {
          #{$ldirection}: -50px;
        }
      }
    }
  }
  .video-js {
    @media #{$cr19-large-up} {
      min-height: auto;
    }
    @media #{$mobile-landscape} {
      max-width: 100vw;
      height: calc(100vh - 20px);
      padding-top: 0;
    }
    .vjs-control-bar {
      background-color: transparent;
    }
    .vjs-big-play-button,
    &.vjs-has-started .vjs-big-play-button {
      display: none;
    }
  }
}

.video-detail-cuepoint-target {
  @media #{$cr19-large-up} {
    display: none;
  }
}
